<template>
	<div class="details">
		<div class="conten_body">  
			<h3 class="consu_title"><a href="/">首页 </a>> <router-link  to="/articleList">热点资讯</router-link></h3>		
			<div class="conten_left">
				<h2 class="con_lef_tit">等级保护和网络安全法的关系？</h2>
				<p class="article_time">
				2019-08-21
				<span class="item_art">万方科技整理</span>
				<span class="vp"><i class="el-icon-view"></i>255</span>
			   </p>
				<section class="articleWords ">
						<p>等级保护工作是国家网络安全的基础性工作，是网络安全法要求我们履行的一项安全责任。网络安全法是网络安全领域的基本法，从国家层面对等级保护工作的法律认可，网络安全法中明确的提到信息安全的建设要遵照等级保护标准来建设。</p><p>　　《网络安全法》第二十一条明确规定 
国家实行网络安全等级保护制度。网络运营者应当按照网络安全等级保护制度的要求，履行下列安全保护义务，保障网络免受干扰、破坏或者未经授权的访问，防止网络数据泄露或者被窃取、篡改：</p><p>　　(一)制定内部安全管理制度和操作规程，确定网络安全负责人，落实网络安全保护责任;</p><p>　　(二)采取防范计算机病毒和网络攻击、网络侵入等危害网络安全行为的技术措施;</p><p>　　(三)采取监测、记录网络运行状态、网络安全事件的技术措施，并按照规定留存相关的网络日志不少于六个月;</p><p>　　(四)采取数据分类、重要数据备份和加密等措施;</p><p>　　(五)法律、行政法规规定的其他义务。</p><p>　　万方安全专注与网络信息安全领域10余年以上，具备国家网络信息安全主管部门颁发的最权威认证资质及国际权威认证资质，安全博士领衔数十位获得国际和国内顶级安全资质的专家团队为您服务。</p>					</section>

          <div class="bottomLine"></div>
          <div class="articleNav">
          <p class="nextArticle">上一篇：<router-link to="consultDetail1">等保2.0和等保1.0分别有哪些标准文件</router-link></p>
          <p class="nextArticle">下一篇：<router-link to="consultDetail3">周晓峰：什么是信息安全风险评估?</router-link></p>
        </div>

			</div>
			<!-- right -->
			<Hotgoods  :contant="fetching"/>
			
		</div>
	</div>
</template>

<script type="text/javascript">
import Hotgoods from "@/components/hotgoods.vue"
// import Selectshop from "@/components/Selectshop.vue"

export default{
components:{
      Hotgoods//,
      // Selectshop
	},
data(){
	return{
		fetching:false,
		
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;
//**/
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
	padding:20px;
}
.consu_title{
	line-height: 32px;
    font-size: 14px;
    padding-left: 20px;
    color: #474747;
}
.con_lef_tit{
	text-align: left;
    font-size: 25px;
    font-weight: 400;
    color: #222222;
    padding:20px 0;
}
.article_time{
	margin-top: 10px;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
}
.articleWords {
	margin-top: 10px;
	line-height: 26px;
    margin-bottom: 15px;
    font-size: 15px;
    color: #666666;
}
.articleWords p{
	font-size:15px;
	text-indent: 2em;
}
.articleWords strong{
	font-weight: bold;
}
.bottomLine{
	width: 100%;
    height: 1px;
    background: #E9E9E9;
    margin-bottom: 20px;
}
.articleNav{
	overflow:hidden;
}
.nextArticle{
    float:left;
	margin-top: 15px;
    font-size: 12px;
    color: #666666;
}
.nextArticle:nth-of-type(2){
	float:right;
}
.item_art{
	padding:0 5px;
}
.vp i{
	padding-right:3px;
}


}

</style>







